import React from 'react';
import {connect} from "react-redux";
import {actionGetAttendanceList, acknowledgeCompleteAttendance} from "../../../../actions/admin/attendance.actions";
import Card from "react-bootstrap/Card";
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../utlis";
import {userConstants} from "../../../../constants";
import Badge from "react-bootstrap/Badge";
import DatePicker from "react-datepicker";
import {CustomDatePicker} from "../../../shared/custom-date-picker";
import axios from '../../../../actions/axiosConfig';
import moment from 'moment';
import {BasicTable} from './filter-attendance'
import {AttendanceDetailModal} from "./attendance-detail-modal";


class AttendanceManagement extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterBy: 'today',
            dateFrom: new Date(),
            dateTill: new Date(),
            approvalStatus: '',
            showModal: false,
            selectedAttendance: {}
        }
    }

    componentDidMount() {
        const { params } = this.props;
        let status = params.approvalStatus === 'all' ? '' : params.approvalStatus;
        this.setState({approvalStatus: status});
        const {filterBy} = this.state;
        this.props.actionGetAttendanceList({filterBy, status});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { params } = this.props;
        if (prevProps.params.approvalStatus !== params.approvalStatus) {
            const {filterBy} = this.state;
            this.props.actionGetAttendanceList({filterBy, status: params.approvalStatus});
            this.setState({approvalStatus: params.approvalStatus});
        }
    }

    exportCSV = async () => {
        const {filterBy, dateFrom, dateTill, approvalStatus} = this.state;
        const params = {
            filterBy,
            dateFrom,
            dateTill,
            status: approvalStatus,
            timeZoneOffSet: (new Date()).getTimezoneOffset()
        };
        axios.get("/attendance/export/file", {params, responseType: 'arraybuffer'}).then(response => {
            var blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'attendance.xlsx');

            document.body.appendChild(link);
            link.click();
        });
    };

    handleFilterChange = (e) => {
        const filterBy = e.target.value;
        this.setState({filterBy});
    };
    onClickSearchByDate = () => {
        const {filterBy, dateFrom, dateTill, approvalStatus} = this.state;
        this.props.actionGetAttendanceList({filterBy, dateFrom, dateTill, status: approvalStatus});
    };

    onClickDetail = (row) => {
        this.setState(
            {
                showModal: true,
                selectedAttendance: row
            }
        )
    }

    renderColumns = () => {
        const {lang} = this.props;
        const selectedOrganization = JSON.parse(localStorage.getItem('selectedOrganization'));
        return [
            {
                name: lang.Name,
                sortable: true,
                selector: 'user.firstName',
                cell: row => row.user.firstName + ' ' + row.user.lastName
            },
            {
                name: lang.Outlet,
                selector: 'outlet.name',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                selector: 'punchInDateTime',
                sortable: true,
                cell: row => convertDateTimeToCustomFormat(row.punchInDateTime)
            },
            {
                name: lang.PunchOutTime,
                selector: 'punchOutDateTime',
                sortable: true,
                cell: row => (
                    row.punchOutDateTime && row.status === userConstants.PUNCH_OUT ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                )
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.ApprovalStatus,
                sortable: true,
                cell: row => (
                    <h6>
                        {row.verification.status === undefined && <Badge variant="light">{lang.Pending}</Badge>}
                        {row.verification.status === "pending" && <Badge variant="light">{lang.Pending}</Badge>}
                        {row.verification.status === "approved" && <Badge variant="success">{lang.Approved}</Badge>}
                        {row.verification.status === "rejected" && <Badge variant="danger">{lang.Rejected}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => {
                    const endTime = row.punchOutDateTime ? row.punchOutDateTime : (new Date()).toISOString();
                    return calculateTimeDiff(endTime, row.punchInDateTime)
                }
            },
            {
                name: '',
                button: true,
                sortable: true,

                cell: row => (selectedOrganization.isSuperAdmin ? <>
                    <i className="fas fa-edit cursor-pointer text-info mr-2"
                       data-toggle="tooltip"
                       data-placement="top"
                       title="Edit User"
                       onClick={() => this.editeAttendance(row._id)}
                    /> | &nbsp;
                </> : null)
            }
        ]
    };
    
    editeAttendance = (id) => {
        this.props.navigate(`/admin/attendanceedit/edit?attendanceId=${id}`)
    }

    handleModalClose = () => {
        this.setState({showModal: false});
    };
    render() {
        const {filterBy, dateFrom, dateTill, approvalStatus, showModal, selectedAttendance} = this.state;
        const {lang, attendanceList} = this.props;

        if (attendanceList) {
            attendanceList.forEach(item => {
                item.punchInDateTime = moment(item.punchInDateTime).format('YYYY-MM-DD HH:mm:ss');
                item.punchOutDateTime = moment(item.punchOutDateTime).format('YYYY-MM-DD HH:mm:ss');
            })
        }
        return (
            <>
                <h4 className="my-3 mx-1">
                    {approvalStatus === 'all' && 'All '}
                    {approvalStatus === 'pending' && 'Pending '}
                    {approvalStatus === 'approved' && 'Approved '}
                    Attendance Records
                </h4>

                <Card>
                    <Card.Body>
                        <div className="row">
                            <div className="col-md-3 form-group">
                                <label>{lang.FilterBy}</label>
                                <select className="form-control" value={filterBy} onChange={this.handleFilterChange}>
                                    <option value="today">{lang.Today}</option>
                                    <option value="week">{lang.LastSevenDays}</option>
                                    <option value="month">{lang.LastThirtyDays}</option>
                                    <option value="threeMonth">{lang.LastNinetyDays}</option>
                                    <option value="year">{lang.LastTwelveMonths}</option>
                                    <option value="DR">{lang.DateRange}</option>
                                </select>
                            </div>
                            {filterBy === 'DR' && (
                                <>
                                    <div className="col-md-3">
                                        <label>{lang.DateFrom}</label>
                                        <DatePicker
                                            dateFormat="dd-MMM-yyyy"
                                            selected={dateFrom}
                                            onChange={date => this.setState({dateFrom: date})}
                                            customInput={<CustomDatePicker/>}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>{lang.DateTo}</label>
                                        <DatePicker
                                            dateFormat="dd-MMM-yyyy"
                                            selected={dateTill}
                                            onChange={date => this.setState({dateTill: date})}
                                            customInput={<CustomDatePicker/>}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="col-md-3 pt-4">
                                <button className="btn btn-primary mt-1 mr-1"
                                        onClick={this.onClickSearchByDate}>{lang.Search}
                                </button>
                                <button className="btn btn-success mt-1" onClick={this.exportCSV}>Export to CSV</button>
                            </div>

                        </div>
                    </Card.Body>
                </Card>

                <Card className="mt-3">
                    <Card.Body>
                        <BasicTable
                            title={lang.Attendance}
                            columns={this.renderColumns()}
                            attendanceList={attendanceList}
                            showAttendanceDetailsModal={(row) => this.onClickDetail(row)}
                        />
                    </Card.Body>
                </Card>
                <AttendanceDetailModal
                    showModal={showModal} handleModalClose={this.handleModalClose}
                    attendanceId={selectedAttendance} lang={lang}
                    handleAcknowledgement={this.handleAcknowledgement}
                    handleCompleteAcknowledgement={(status) => acknowledgeCompleteAttendance(selectedAttendance._id, {status})}
                />
            </>
        );
    }
}

const mapStateToProps = ({langReducer, admin: {attendanceReducer}}) => ({
    lang: langReducer.lang,
    attendanceList: attendanceReducer.V_AttendanceRecords
});

export default connect(mapStateToProps, ({actionGetAttendanceList}))(AttendanceManagement);
