import React from 'react';
import {connect} from "react-redux";
import {getEmployees, deleteEmployee, restoreEmployee} from '../../../../actions/admin/employee.actions';
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/esm/Button";
import Modal from 'react-bootstrap/Modal';

class EmployeesList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            dataSet: {targetId : null, operation: null},
        };
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleConfirmModal = this.handleConfirmModal.bind(this);
    }
    componentDidMount() {
        this.props.getEmployees();
    }

    editEmployee = (groupId) => () => {
        this.props.navigate(`/admin/employees/edit?employeeId=${groupId}`)
    };

    handleCloseModal = () => {
        this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });

    };

    handleOpenModal = (targetId, typeOp) => {
        this.setState({ showModal: true, dataSet: {targetId : targetId, operation: typeOp.toUpperCase()} });
    };

    handleConfirmModal = () => {
        if (this.state.dataSet.operation === "DELETE") {
            this.props.deleteEmployee(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.getEmployees();
                this.props.navigate('/admin/employees');
            });
        }
        if (this.state.dataSet.operation === "RESTORE") {
            this.props.restoreEmployee(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.getEmployees();
                this.props.navigate('/admin/employees');
            });
        }

    }

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.AccountType,
                cell: row => row.org.length > 0 ? row.org[0].isAdmin ? <Badge variant="success">Admin</Badge>  : null : null 

            },
            {
                name: lang.Username,
                selector: 'userName',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.userName} </div>
                )
            },
            {
                name: lang.Email,
                selector: 'email',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.email} </div>
                )
            },

            {
                name: lang.Contact,
                selector: 'contact',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.contact} </div>
                )
            },
            {
                name: lang.Outlet,
                cell: row => (
                <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.outlet.map(outlet => outlet.name).join(',')} </div>)
                
            },
            {
                name: lang.Supervisor,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.org.length > 0 ? row.org[0].employee?.otSupervisor.map(sup => sup.userName).join(",") : ""} </div>
                )
            },
            {
                name: lang.LeaveManager,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.org.length > 0 ? row.org[0].employee?.leaveManager.map(manager => manager.userName).join(",") : ""} </div>
                )
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {
                            (row.status.toLowerCase() === 'a' || row.status.toLowerCase() === 'active') && <Badge variant="success">{lang.Active}</Badge>
                        }
                        {
                            (row.status.toLowerCase() === 'pre-active') && <Badge variant="secondary">{lang.ActivationPending}</Badge>
                        }
                        {
                            row.status.toLowerCase() === 'deleted' && 
                            <Badge variant="warning">{lang.Deleted}</Badge>
                        }
                        {
                            row.status.toLowerCase() === 'inactive' && 
                            <Badge variant="secondary">{lang.Inactive}</Badge>
                        }
                    </h6>
                )
            },
            {
                name: '',
                button: true,
                cell: row => (
                    <>
                        <i className="fas fa-user-edit cursor-pointer text-info mr-2"
                           data-toggle="tooltip"
                           data-placement="top"
                           title="Edit User"
                           onClick={this.editEmployee(row._id)}/> | &nbsp;
                           {row.isDeleted ? 
                            <i className="fas fa-undo cursor-pointer text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Restore User"
                                onClick={this.handleOpenModal.bind(this, row._id, "restore")}
                            />
                            :
                            <i className="far fa-trash-alt cursor-pointer text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete User"
                                onClick={this.handleOpenModal.bind(this, row._id, "delete")}
                            />
                           }
                    </>)
            }
        ];
    };

    render() {
        const {employees, lang} = this.props;
    
        return (
            <>
                <Container fluid className="pt-4">
                    <Card>
                        <Card.Body>
                            <DataTable
                                title={lang.AllEmployees}
                                columns={this.renderColumns()}
                                data={employees}
                                pagination={true}
                            />
                        </Card.Body>
                    </Card>
                </Container>
                
                <Modal show={this.state.showModal} onHide={this.handleCloseModal.bind(this)}>
                    <Modal.Header closeButton>
                    <Modal.Title>{lang.Confirmation}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><b>"{this.state.dataSet.operation}"</b> user, {lang.ConfirmationQuestion} </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal.bind(this)}>
                        {lang.No}
                    </Button>
                    <Button variant="primary" onClick={this.handleConfirmModal.bind(this)}>
                        {lang.Yes}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({admin: {employeeReducer}, langReducer}) => ({
    employees: employeeReducer.employees,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({getEmployees, deleteEmployee, restoreEmployee}))(EmployeesList);
