import React from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Layout from "./layout/Layout";

export const PrivateRoute = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const auth = localStorage.getItem('user');
    if (auth) {
        return (
            <Layout location={location} navigate={navigate}>
                <Outlet navigate={navigate} />
            </Layout>
        )
    } else {
        return <Navigate to={{ pathname: '/login' }} />
    }
}