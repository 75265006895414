import React from 'react';
import Container from "react-bootstrap/Container";
import {connect} from "react-redux";
import {actionGetAnnouncement} from '../../../../../actions/admin/announcement.actions';
import Card from "react-bootstrap/Card";
import {AnnouncementDetail} from "./Details";
import {AcknowledgementReport} from "./acknowledgement-report";


class ViewAnnouncement extends React.Component {

    componentDidMount() {

        const {search} = this.props.location;
        const queryParams = new URLSearchParams(search);
        if (queryParams.get('id')) {
            const announcementId = queryParams.get('id');
            this.props.actionGetAnnouncement(announcementId);
        }
    }

    render() {
        const {announcement: {content = {}, user = {}}, lang} = this.props;

        return (

            <Container fluid className="mt-4 mb-5">
                <Card>
                    <Card.Body>
                        <Card.Title>{lang.AnnouncementDetails}</Card.Title>
                        <AnnouncementDetail content={content} lang={lang}/>
                    </Card.Body>
                </Card>
                {(Object.keys(user).length > 0 && Object.keys(lang).length > 0 && Object.keys(content).length > 0) &&
                    <Card className="mt-3">
                        <Card.Body>
                            <AcknowledgementReport users={user} content={content} lang={lang}/>
                        </Card.Body>
                    </Card>
                }
                

            </Container>

        );
    }
}

const mapStateToProps = ({admin: {announcementReducer}, langReducer}) => ({
    announcement: announcementReducer.announcement,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {actionGetAnnouncement})(ViewAnnouncement);

