import React from "react";
import {connect} from "react-redux";
import {addGroup, getGroup, updateGroup} from "../../../../actions/admin/group.actions";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";


class GroupDefinition extends React.Component {

    constructor(props) {
        super(props);

        let date = new Date();
        this.state = {
            action: '',
            form: {
                name: '',
                description: '',
                remarks: '',
                status: 'A',
                validated: false,
                offset: date.getTimezoneOffset()
            }
        };
    }


    componentDidMount() {
        const { params } = this.props;
        this.setState({action: params.action});
        if (params.action === 'edit') {
            const {search} = this.props.location;
            const queryParams = new URLSearchParams(search);
            if (queryParams.get('groupId')) {
                const groupId = queryParams.get('groupId');
                this.props.getGroup(groupId).then(() => {
                    this.setFormStateForEdit();
                });
            }
        }
    }

    createNewGroup = () => {
        this.props.addGroup(this.state.form).then(() => {
            this.resetFormState();
            this.props.navigate('/admin/groups');
        }).catch(error => {

        });
    };

    handleChange = (e) => {
        const {form} = this.state;
        form[e.target.name] = e.target.value;
        this.setState(form)
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const htmlForm = event.currentTarget;
        if (htmlForm.checkValidity() === false) {
            event.stopPropagation();
        }
        const {form} = this.state;
        form.validated = true;
        this.setState({form});

        this.state.action === 'add' ? this.createNewGroup() : this.updateGroup();
    };

    setFormStateForEdit = () => {
        const {form} = this.state;
        const {group} = this.props;

        form._id = group._id;
        form.name = group.name;
        form.description = group.description;
        form.remarks = group.remarks;
        form.status = group.status;

        this.setState({form});
    };

    resetFormState = () => {
        const {form} = this.state;
        form.name = '';
        form.description = '';
        form.remarks = '';
        form.validated = false;
        this.setState({form});
    };

    updateGroup = () => {
        this.props.updateGroup(this.state.form).then(() => {
            this.props.navigate('/admin/groups');
        });
    };

    render() {
        const {action, form: {name, description, remarks, status, validated}} = this.state;
        const {lang} = this.props;
        return (
            <Container fluid>

                <Card className="mt-4 shadow">
                    <Card.Body>
                        <Card.Title>{action === 'add' ? lang.AddGroup : lang.EditGroup}</Card.Title>
                        <Form noValidate validated={validated} onSubmit={this.handleSubmit}>
                            <Form.Group controlId="groupName">
                                <Form.Label>{lang.Name}</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={name}
                                    name="name"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="groupDescription">
                                <Form.Label>{lang.Description}</Form.Label>
                                <Form.Control
                                    as="textarea" rows="5"
                                    required
                                    type="text"
                                    value={description}
                                    name="description"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="groupRemarks">
                                <Form.Label>{lang.Remarks}</Form.Label>
                                <Form.Control
                                    as="textarea" rows="3"
                                    type="text"
                                    value={remarks}
                                    name="remarks"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group controlId="groupStatus">
                                <Form.Label>{lang.Status}</Form.Label>
                                <Form.Control
                                    as="select"
                                    type="text"
                                    value={status}
                                    name="status"
                                    onChange={this.handleChange}
                                >
                                    <option value="A">{lang.Active}</option>
                                    <option value="I">{lang.Inactive}</option>
                                </Form.Control>
                            </Form.Group>
                            <Button type="submit" variant="primary">{action === 'add' ? lang.Add : lang.Save}</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

const mapStateToPrpo = ({admin: {groupReducer}, langReducer}) => ({
    group: groupReducer.group,
    lang: langReducer.lang
});


export default connect(mapStateToPrpo, ({addGroup, getGroup, updateGroup}))(GroupDefinition);
