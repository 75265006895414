import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// console.log("API_URL:", API_URL);
const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

axiosInstance.interceptors.request.use(
    async config => {
        const c = config;
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            const {token} = user;
            if (token) {
                //console.log(token);
                c.headers['auth-token'] = token;
            }
        }
        if (localStorage.getItem('selectedOrganization')) {
            const org = JSON.parse(localStorage.getItem('selectedOrganization'));
            c.headers['orgId'] = org?.corpUid;
        }

        return c;
    },
    error => Promise.reject(error),
);

function interceptSuccess(response) {
    // success interception logic here
    return response;
}

function interceptError(error) {

    if (error.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        console.log(error.request);
    } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
    }

    return Promise.reject(error);
}

// interceptors
axiosInstance.interceptors.response.use(interceptSuccess, interceptError);

export default axiosInstance;
