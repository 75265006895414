import React from 'react';
import {connect} from "react-redux";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import {actionGetContents} from "../../../../actions/admin/content.actions";
import DataTable from "react-data-table-component";

class ContentManagement extends React.Component {

    componentDidMount() {
        this.props.actionGetContents();
    }

    renderTableColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Title,
                selector: 'title',
                sortable: true,
            },
            {
                name: lang.Status,
                sortable: true,
                cell: row => (
                    <>
                        {row.status === 'A' ? lang.Active : lang.Inactive}
                    </>
                )
            },
            {
                name: lang.Actions,
                button: true,
                cell: row => (
                    <>
                        <i className="fas fa-eye cursor-pointer mr-1 text-info"
                           onClick={() => this.onViewContent(row._id)}/>
                    </>)
            }
        ];
    };

    onViewContent = (contentId) => {
        this.props.navigate('/admin/contents/view?id=' + contentId);
    };

    render() {
        const {contents, lang} = this.props;
        return (
            <Container fluid className="mt-4 mb-5">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllContents}
                            columns={this.renderTableColumns()}
                            data={contents}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>)
    }
}

const mapStateToProps = ({admin: {contentReducer}, langReducer}) => ({
    contents: contentReducer.contents,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {actionGetContents})(ContentManagement);
