import React from 'react';
import {connect} from "react-redux";
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import Badge from "react-bootstrap/Badge";
import Modal from 'react-bootstrap/Modal';
import {getOutletsAsAdmin, deleteOutlet, restoreOutlet} from "../../../../actions/admin/outlet.actions";

class OutletsList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            dataSet: {targetId : null, operation: null},
        };
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleConfirmModal = this.handleConfirmModal.bind(this);
    }

    componentDidMount() {
        this.props.getOutletsAsAdmin();
    }

    editOutlet = (outletId) => () => {
        this.props.navigate(`/admin/departments/edit?outletId=${outletId}`)
    };

    handleCloseModal = () => {
        this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });

    };

    handleOpenModal = (targetId, typeOp) => {
        this.setState({ showModal: true, dataSet: {targetId : targetId, operation: typeOp.toUpperCase()} });
    };

    handleConfirmModal = () => {
        if (this.state.dataSet.operation === "DELETE") {
            this.props.deleteOutlet(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.getOutletsAsAdmin();
                this.props.navigate('/admin/departments');
            });
        }
        if (this.state.dataSet.operation === "RESTORE") {
            this.props.restoreOutlet(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.getOutletsAsAdmin();
                this.props.navigate('/admin/departments');
            });
        }

    }

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                selector: 'name',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.name} </div>
                )
            },
            {
                name: lang.Comments,
                selector: 'comment',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.comment} </div>
                )
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>  
                        {
                            (row.status.toLowerCase() === 'a' || row.status.toLowerCase() === 'active') && <Badge variant="success">{lang.Active}</Badge>
                        }
                    
                        {
                            (row.status.toLowerCase() === 'pre-active') && <Badge variant="secondary">{lang.ActivationPending}</Badge>
                        }
                        {
                            row.status.toLowerCase() === 'deleted' && 
                            <Badge variant="warning">{lang.Deleted}</Badge>
                        }
                        {
                            row.status.toLowerCase() === 'inactive' && 
                            <Badge variant="secondary">{lang.Inactive}</Badge>
                        }
                    </h6>
                )
            },
            {
                name: '',
                button: true,
                cell: row => (
                    <>
                        <Button variant="info" size="sm" className="mr-1" onClick={this.editOutlet(row._id)}><i
                            className="fas fa-wrench"/></Button>
                        
                        |
                        {row.isDeleted ? 
                            <i className="fas fa-undo cursor-pointer ml-1 text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Restore User"
                                onClick={this.handleOpenModal.bind(this, row._id, "restore")}
                            />
                            :
                            <i className="far fa-trash-alt cursor-pointer ml-1 text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete User"
                                onClick={this.handleOpenModal.bind(this, row._id, "delete")}
                            />
                           }
                    </>)
            }
        ];
    };

    render() {
        const {outlets, lang} = this.props;
        return (
            <>
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.AllOutlets}
                            columns={this.renderColumns()}
                            data={outlets}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
                <Modal show={this.state.showModal} onHide={this.handleCloseModal.bind(this)}>
                    <Modal.Header closeButton>
                    <Modal.Title>{lang.Confirmation}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><b>"{this.state.dataSet.operation}"</b> announcement, {lang.ConfirmationQuestion} </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal.bind(this)}>
                        {lang.No}
                    </Button>
                    <Button variant="primary" onClick={this.handleConfirmModal.bind(this)}>
                        {lang.Yes}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({admin: {outletReducer}, langReducer}) => ({
    outlets: outletReducer.outlets,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({getOutletsAsAdmin, deleteOutlet, restoreOutlet}))(OutletsList);
