import React from "react";
import {connect} from "react-redux";

import {Formik, FieldArray, Field} from "formik";
import * as Yup from "yup";
import Container from "react-bootstrap/Container";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { getCorpData, updateCorpData } from "../../../../../actions/admin/settings.actions";

const countryJson = require("countrycitystatejson");

class OutletDefinition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            action: 'view',
            countries: [],
            form: {
              "corpName": "",
              "alias": [],
              "uen": "",
              "dateOfIncorporation": "",
              "contactNo": "",
              "country": "",
              "state": "",
              "city": "",
              "postalCode": "",
              "address": [""]
            }
        };
    }


    componentDidMount() {
        this.props.getCorpData().then(() => {
          this.populateFormData();
        });
        const countries = countryJson.getCountries().sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
        this.setState((prevState) => ({
            ...prevState,
            countries: countries
        }));
    }

    handleChange = (e) => {
        const {form} = this.state;
        form[e.target.name] = e.target.value;
        this.setState(form)
    };

    setFieldValue = (fieldName, value) => {
        const {form} = this.state;
        form[fieldName] =value;
        this.setState(form)
    };

    handleSubmit = async (values) => {
        // event.preventDefault();
        // const htmlForm = event.currentTarget;
        // if (htmlForm.checkValidity() === false) {
        //     event.stopPropagation();
        // }
        // const {form} = this.state;
        // this.setState({form});

        this.props.updateCorpData(values).then(() => {
            this.setState((prevState) => ({
                ...prevState,
                action: "view",
                form: values
            }));
            this.props.navigate('/admin/settings/organization/detail');
        });
    };

    getStates = (countryCode) => {
        if (countryCode) {
            return countryJson.getStatesByShort(countryCode);
        }
        return [];
    };

    getCities = (countryCode, stateCode) => {
        if (countryCode && stateCode) {
            return countryJson.getCities(countryCode, stateCode);
        }
        return [];
    };
    populateFormData = () => {
        const {form} = this.state;
        const {corpData} = this.props;
        form.corpName = corpData.corpName;
        form.alias = corpData.alias;
        form.uen = corpData.uen;
        form.dateOfIncorporation = corpData.dateOfIncorporation;
        form.contactNo = corpData.contactNo;
        form.country = corpData.country;
        form.state = corpData.state;
        form.city = corpData.city;
        form.postalCode = corpData.postalCode;
        form.address = corpData.address;
        this.setState({form});
    };

    handleChangeAction = () => {
        console.log("a")
        let {action} = this.state;
        action = 'edit';
        this.setState((prevState) => ({
            ...prevState,
            action: action
        }));
    };

    updateOutlet = () => {
        this.props.updateOutlet(this.state.form).then(() => {
            this.props.navigate('/admin/departments');
        });
    };

    render() {
        const {action, form, countries} = this.state;
        const {lang} = this.props;
        return (
            <Container fluid>
                <Card className="mt-4 shadow">
                    <Card.Body>
                        <Card.Title>{action === 'view' ? lang.organizationDetails : lang.editOrganizationDetails}</Card.Title>
                        {action === 'view' && (
                            <i
                                className="fas fa-pencil-alt text-danger mt-3 cursor-pointer"
                                onClick={() => this.handleChangeAction()}
                            />
                        )}
                        <Formik
                            validationSchema={editValidation_schema}
                            initialValues={form}
                            enableReinitialize={true}
                            onSubmit={this.handleSubmit}
                        > 
                        {({
                            handleSubmit,
                            handleChange,
                            setFieldValue,
                            values
                        }) =>(
                            <Form noValidate onSubmit={handleSubmit}>
                            
                                <Form.Row>
                                    <Form.Group as={Col} md={6} controlId="corpName">
                                        <Form.Label>{lang.OrganizationName}</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            value={values.corpName}
                                            name="corpName"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="groupAlias">
                                        <Form.Label>Alias</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={values.alias}
                                            name="alias"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                        />
                                    </Form.Group>
                                
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md={6} controlId="groupUen">
                                        <Form.Label>UEN</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={values.uen}
                                            name="uen"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md={6} controlId="groupDateOfIncorporation">
                                        <Form.Label>Date Of Incorporation</Form.Label>
                                        <DatePicker
                                            selected={
                                                values.dateOfIncorporation ? new Date(values.dateOfIncorporation) : values.dateOfIncorporation
                                            }
                                            onChange={(date) => setFieldValue("DateOfIncorporation", date)}
                                            dateFormat="dd-MMM-yyyy"
                                            className="form-control"
                                            readOnly={action === 'view'}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md={6} controlId="groupContact">
                                        <Form.Label>{lang.Contact}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={values.contactNo}
                                            name="contactNo"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                        />
                                    </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="country">
                                            <Form.Label>{lang.Country}</Form.Label>
                                            <Select
                                                options={countries.map((country) => ({
                                                    value: country.shortName,
                                                    label: country.emoji + "  " + country.name,
                                                }))}
                                                value={countries
                                                    .filter(
                                                        (country) => country.shortName === values.country
                                                    )
                                                    .map((country) => ({
                                                        value: country.shortName,
                                                        label: country.emoji + "  " + country.name,
                                                    }))}
                                                onChange={(selected) =>
                                                    setFieldValue("country", selected.value)
                                                }
                                                isSearchable
                                                isDisabled={action === 'view'}
                                            />
                                            <Form.Control
                                                name="country"
                                                defaultValue={values.country}
                                                className="d-none"
                                            />
                                        </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md={6} controlId="state">
                                        <Form.Label>{lang.State}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={values.state}
                                            name="state"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                            disabled={action === 'view'}
                                        >
                                            <option>Select</option>
                                            {this.getStates(values.country)?.map((state) => (
                                                <option value={state} key={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} md={6} controlId="city">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            as="select"
                                            value={values.city}
                                            name="city"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                            disabled={action === 'view'}
                                        >
                                            <option>Select</option>
                                            {this.getCities(values.country, values.state)?.map((city) => (
                                                <option value={city} key={city}>
                                                    {city}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md={6} controlId="groupPostalCode">
                                        <Form.Label>Postal Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={values.postalCode}
                                            name="postalCode"
                                            onChange={handleChange}
                                            readOnly={action === 'view'}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                {(values.address.length > 0 || action === 'edit') && (
                                    <Form.Label>Address</Form.Label>
                                )}
                                <FieldArray
                                            name="address"
                                            render={({push, remove}) => (
                                                <Form.Row>
                                                    {values.address.map((address, index) => (
                                                        <React.Fragment key={`address-${index}`}>
                                                            <Form.Group
                                                                as={Col}
                                                                md={index !== 0 ? 11 : 12}
                                                                controlId={`addressLine${index}`}
                                                            >
                                                                <Form.Label>
                                                                    {lang.AddressLine} {index + 1}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    value={address}
                                                                    name={`address[${index}]`}
                                                                    onChange={handleChange}
                                                                    readOnly={action === 'view'}
                                                                />
                                                            </Form.Group>
                                                            {(index !== 0 && action === 'edit') && (
                                                                <Form.Group
                                                                    as={Col}
                                                                    md={1}
                                                                    className="text-center pt-4"
                                                                >
                                                                    <i
                                                                        className="far fa-trash-alt text-danger mt-3 cursor-pointer"
                                                                        onClick={() => remove(index)}
                                                                    />
                                                                </Form.Group>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                    { action === 'edit' && (
                                                        <Form.Group>
                                                            <Button
                                                                type="button"
                                                                variant="info"
                                                                size="sm"
                                                                onClick={() => push("")}
                                                            >
                                                                Add more addresses
                                                            </Button>
                                                        </Form.Group>

                                                    )}
                                                </Form.Row>
                                            )}
                                        />
                                        { action === 'edit' && (
                                <Button type="submit" variant="primary">{lang.Save}</Button>
                        )}
                            </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Container>
        )
    }
}

const mapStateToProps = ({admin: {settingReducer}, langReducer}) => ({
    corpData: settingReducer.corpData,
    lang: langReducer.lang
});

const editValidation_schema = Yup.object({
    corpName: Yup.string().required("Field is required")
});

export default connect(mapStateToProps, ({getCorpData, updateCorpData}))(OutletDefinition);
