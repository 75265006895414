import React from 'react';
import {Card} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {calculateTimeDiff, convertDateTimeToCustomFormat} from "../../../../../../utlis";
import {userConstants} from "../../../../../../constants";
import Badge from "react-bootstrap/Badge";
import {connect} from "react-redux";
import {
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    approveAttendanceRecord,
    setSelectedAttendance
} from "../../../../../../actions/user/attendance.actions";
import {actionGetAttendanceVerificationOptions} from "../../../../../../actions/user/attendance-verification-options.actions";
import {AttendanceAcknowledgment} from "../../../../shared/attendance/attendance-acknowledgement";

class PendingAckAttendanceRecords extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showAttendanceVerificationModal: false,
            showAttendanceAckModal: false,
            selectedAttendance: {},
        }
    }

    componentDidMount() {
        this.props.actionGetAttendanceVerificationOptions();
    };

    renderColumns = () => {

        const {lang} = this.props;
        const colums = [
            {
                name: lang.Name,
                cell: row => row.user.firstName
            },
            {
                name: lang.Outlet,
                selector: 'outlet',
                sortable: true,
                cell: row => row.outlet?.name
            },
            {
                name: lang.PunchInTime,
                selector: 'punchInDateTime',
                sortable: true,
                cell: row => {
                    if (!row.ifLate) {
                        return convertDateTimeToCustomFormat(row.punchInDateTime)
                    } else {
                        return convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchInDateTime)
                    }
                }
            },
            {
                name: lang.PunchOutTime,
                selector: 'punchOutDateTime',
                sortable: true,
                cell: row => {
                    if (row.ifLate) {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.changeRequest?.adjustedTime.punchOutDateTime) : '-----'

                    } else {
                        return row.punchOutDateTime ? convertDateTimeToCustomFormat(row.punchOutDateTime) : '-----'
                    }
                }
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>
                        {row.status === userConstants.PUNCH_IN && <Badge variant="success">{lang.PunchedIn}</Badge>}
                        {row.status === userConstants.PUNCH_OUT && <Badge variant="light">{lang.PunchedOut}</Badge>}
                        {row.status === userConstants.BREAK_START && <Badge variant="warning">{lang.OnBreak}</Badge>}
                    </h6>
                )
            },
            {
                name: lang.TotalHours,
                sortable: true,
                cell: row => {
                    return this.calculateTotalTime(row);
                }
            }

        ];

        if (this.props.status !== 'verified') {
            colums.push({
                name: lang.Actions,
                button: true,
                cell: row => (
                    <span
                        className="btn btn-sm btn-info"
                        onClick={() => {
                            this.props.setSelectedAttendance(row);
                            this.setState({showAttendanceAckModal: true});
                        }}>
                           <i className="fas fa-camera"/>
                    </span>
                )
            })
        }
        return colums;
    };

    calculateTotalTime = (record) => {
        const startTime = record.ifLate ? record.changeRequest?.adjustedTime.punchInDateTime : record.punchInDateTime;
        const endTime = record.punchOutDateTime ? record.ifLate ? record.changeRequest?.adjustedTime.punchOutDateTime : record.punchOutDateTime : (new Date()).toISOString();
        return calculateTimeDiff(endTime, startTime);
    };

    getAttendanceRecords = () => {
        return this.props.attendanceHistory?.filter(attendance => {
            if (attendance.punchInAck && attendance.punchOutAck) {
                if (attendance.breakTime.length) {
                    return !attendance.breakTime.some(breakTime => breakTime.startAck && breakTime.endAck)
                } else {
                    return false;
                }
            } else {
                return true;
            }
        });
    };
    handleClosing = () => {
        this.setState({showAttendanceAckModal: false})
        this.props.actionGetAttendanceVerificationOptions()
    }


    render() {
        const customStyles = {
            header: {
                style: {
                    minHeight: '50px',
                    autoWidth: true
                },
            },
            headRow: {
                style: {
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px',

                },
            },
            headCells: {
                style: {
                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',

                    },
                },
            },
            cells: {
                style: {

                    '&:not(:last-of-type)': {
                        borderRightStyle: 'solid',
                        borderRightWidth: '1px',
                    },
                },
            },
        };
        const {showAttendanceAckModal} = this.state;
        const {lang, selectedAttendance} = this.props;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>{lang.PendingAcknowledgements}</Card.Title>
                    <DataTable
                        customStyles={customStyles}
                        columns={this.renderColumns()}
                        data={this.getAttendanceRecords()}
                        pagination={true}
                        highlightOnHover
                    />
                    <AttendanceAcknowledgment
                        attendance={selectedAttendance}
                        showModal={showAttendanceAckModal}
                        handleModalClose={() => this.handleClosing()}
                        canAcknowledge={true}
                        handleSubmitAcknowledgement={this.props.acknowledgeAttendance}
                        handleBulkAcknowledgement={this.props.acknowledgeCompleteAttendance}
                        lang={lang}/>

                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = ({user: {attendanceReducer, ARVO_Reducer}, langReducer}) => ({
    attendanceHistory: attendanceReducer.attendanceHistory,
    selectedAttendance: attendanceReducer.selectedAttendance,
    ARVO_Fields: ARVO_Reducer.ARVO_Fields,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({
    acknowledgeAttendance,
    acknowledgeCompleteAttendance,
    approveAttendanceRecord,
    actionGetAttendanceVerificationOptions,
    setSelectedAttendance
}))(PendingAckAttendanceRecords);
