import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {convertDateToCustomFormat} from "../../../../../utlis";

export const AnnouncementDetail = (props) => {
    const {content, lang} = props;
    return (
        <>
            <Row className='form-group'>
                <Col md={3}>
                    <span className="font-weight-bold">{lang.AnnouncementTitle}</span>
                </Col>
                <Col md={9}>{content.title}</Col>
            </Row>
            <Row className='form-group'>
                <Col md={3}>
                    <span className="font-weight-bold">{lang.AnnouncementFor}</span>
                </Col>
                <Col md={9}>
                    {content.announcementFor === "groups" && "Groups"}
                    {content.announcementFor === "employees" && "Employees"}
                    {content.announcementFor === "outlets" && "Departments"}
                    {content.announcementFor === "everyone" && "Everyone"}
                </Col>
            </Row>
            {content.announcementFor === "groups" && (
                <Row className='form-group'>
                    <Col md={3}>
                        <span className="font-weight-bold">{lang.Groups}</span>
                    </Col>
                    <Col md={9}>
                        {content.targetGroup && content.targetGroup.map(group => (
                            <span className="mr-2" key={group._id}>{group.name},</span>))}
                    </Col>
                </Row>
            )}
            {content.announcementFor === "outlets" && (
                <Row className='form-group'>
                    <Col md={3}>
                        <span className="font-weight-bold">{lang.Outlets}</span>
                    </Col>
                    <Col md={9}>
                        {content.targetOutlet && content.targetOutlet.map(outlet => (
                            <span className="mr-2" key={outlet._id}>{outlet.name},</span>))}
                    </Col>
                </Row>
            )}
            {content.announcementFor === "employees" && (
                <Row className='form-group'>
                    <Col md={3}>
                        <span className="font-weight-bold">{lang.Employees}</span>
                    </Col>
                    <Col md={9}>
                        {content.targetUser && content.targetUser.map(user => (
                            <span className="mr-2" key={user._id}>{user.userName},</span>))}
                    </Col>
                </Row>
            )}

            <Row className="form-group">
                <Col md={3}>
                    <span className="font-weight-bold">{lang.FromDate}</span>
                </Col>
                <Col md={9}>{convertDateToCustomFormat(content.fromDate)}</Col>
            </Row>

            <Row className="form-group">
                <Col md={3}>
                    <span className="font-weight-bold">{lang.ToDate}</span>
                </Col>
                <Col md={9}>{convertDateToCustomFormat(content.tillDate)}</Col>
            </Row>

            <Row className="form-group">
                <Col md={3}>
                    <span className="font-weight-bold">{lang.Description}</span>
                </Col>
                <Col md={9}>
                    <div dangerouslySetInnerHTML={{__html: content.description}}/>
                </Col>
            </Row>

        </>
    );
};
