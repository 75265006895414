import {trackPromise} from "react-promise-tracker";
import axios from "../axiosConfig";
import {toast} from "react-toastify";
import {adminConstants} from "../../constants";

export const actionsCreateAnnouncement = (announcement) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`admin/contents/create`, announcement));
        toast.success('New announcement is created.');
        dispatch({
            type: adminConstants.CREATE_ANNOUNCEMENT,
            payload: response.data.data
        });
    } catch (error) {
        toast.error('Error occurred while saving group');
        throw error;
    }
};

export const actionGetAnnouncements = () => async dispatch => {
    const response = await trackPromise(axios.get(`admin/announcements/list`));
    dispatch({
        type: adminConstants.GET_ANNOUNCEMENTS,
        payload: response.data.data
    });
};

export const actionGetAnnouncement = (announcementId) => async dispatch => {
    const response = await trackPromise(axios.get(`admin/contents/list/${announcementId}`));
    dispatch({
        type: adminConstants.GET_ONE_ANNOUNCEMENT,
        payload: response.data.data
    });
};
export const actionDeleteAnnouncement = (announcementId) => async dispatch => {
    const response = await trackPromise(axios.delete(`admin/contents/delete/${announcementId}`));
    dispatch({
        type: adminConstants.GET_ONE_ANNOUNCEMENT,
        payload: response.data.data
    });
};
export const actionRestoreAnnouncement = (announcementId) => async dispatch => {
    const response = await trackPromise(axios.put(`admin/contents/restore/${announcementId}`));
    dispatch({
        type: adminConstants.GET_ONE_ANNOUNCEMENT,
        payload: response.data.data
    });
};

