import {adminConstants} from "../../constants";

let selectedOrg = JSON.parse(localStorage.getItem('selectedOrganization'));
const initialState = {
    corpData: {},
    selectedOrganization: selectedOrg ? selectedOrg : null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case adminConstants.GET_ADMIN_ORGANIZATIONS:
            return {
                ...state,
                corpData: action.payload
            };

        case adminConstants.ACTION_UPDATE_ORGANIZATION:
            localStorage.setItem('selectedOrganization', JSON.stringify(action.payload));
            return {
                ...state,
                corpData: action.payload,
                selectedOrganization: action.payload
            };
        default:
            return state;
    }
}
