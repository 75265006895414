import React from 'react';
import './App.css';
import {Provider} from 'react-redux';
import store from '../store';
import {PromiseLoader} from "./promise-loader/PomiseLoader";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {RootRoute} from "./RootRoute";

function App() {
    return (
        <Provider store={store}>
            <PromiseLoader/>
            <ToastContainer position="bottom-right"/>
            <RootRoute />
            {/*<Switch>*/}
            {/*    <Route path="/home">*/}
            {/*        <Layout />*/}
            {/*    </Route>*/}
            {/*    <Route path="/login">*/}
            {/*        <Login/>*/}
            {/*    </Route>*/}
            {/*</Switch>*/}
        </Provider>
    );
}

export default App;
