import React, {useEffect} from 'react';
import {Route, Routes, useNavigate, useLocation, useParams} from 'react-router-dom';
import { matchPath } from "react-router";
import {AdminRoute} from "./saas-user/admin";
import {UserRoute} from "./saas-user/user";
import Login from './login/Login';
import JumpAuthorize from './jumpAuthorize';
import {PrivateRoute} from "./PrivateRoute";
import {AccountConfirmation} from "./saas-user/shared/account-confirmation";
import SelectOrganization from "./saas-user/shared/select-organization";
import {useDispatch} from "react-redux";
import {commonConstants} from "../constants";
import language from "../language";

// admmin Route
import Dashboard from "./saas-user/admin/dashboard/Dashboard";
import GroupsList from "./saas-user/admin/group-management/GroupsList";
import GroupDefinition from "./saas-user/admin/group-management/GroupDefinition";
import EmployeesList from "./saas-user/admin/employee-management/EmployeesList";
import EmployeeDefinition from "./saas-user/admin/employee-management/EmployeeDefinition";
import AdminAnnouncement from "./saas-user/admin/annoucements";
import AnnouncementDefinition from "./saas-user/admin/annoucements/AnnouncementDefinition";
import ContentManagement from "./saas-user/admin/content-management";
import ContentDefinition from "./saas-user/admin/content-management/ContentDefinition";
import ViewContent from "./saas-user/admin/content-management/ViewContent";
import ViewAnnouncement from "./saas-user/admin/annoucements/view-announement";
import OutletsList from "./saas-user/admin/outlet-management/OutletsList";
import OutletDefinition from "./saas-user/admin/outlet-management/OutletDefinition";
import AttendanceManagement from "./saas-user/admin/attendance-management";
import Settings from "./saas-user/admin/settings";
import SettingsCompany from "./saas-user/admin/settings/general/company-info";
import AttendanceSettings from "./saas-user/admin/attendance-management/attendance-settings";
import AttendancePendingAckApprovals
    from "./saas-user/admin/attendance-management/attendance-pending-ack-approvals";
import {PendingAttendances} from "./saas-user/admin/attendance-management/pending";
import {RejectedAttendances} from "./saas-user/admin/attendance-management/rejected";
import AccountSettings from "./saas-user/shared/account-settings";
import CreateLeaves from "./saas-user/admin/leave-management/create-leaves";
import EditeAttendance from "./saas-user/admin/attendance-management/EditeAttendance";
import ActivityLog from "./saas-user/admin/activity-log";

// user route
import UserDashboard from "./saas-user/user/dashboard/UserDashboard";
import UserAttendance from "./saas-user/user/attendance";
import UserAnnouncements from "./saas-user/user/announcements";
import UserViewAnnouncement from "./saas-user/user/announcements/view-announcement";
import UserActivityLog from "./saas-user/user/activity-log";
import UserViewAttendanceDetails from "./saas-user/user/attendance/view-attendance-details";

export const RootRoute = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getParams = (path) => {
        const match = matchPath(
            { path: path },
            location.pathname
        )
        return match?.params;
    }

    useEffect(() => {
        getLanguage();
    });

    const getLanguage = () => {
        const locale = localStorage.getItem('locale') || 'en';
        const langObj = language[locale];
        dispatch({type: commonConstants.ACTION_GET_LANG, payload: {lang: langObj.lang, emoji: langObj.emoji, locale}});
    };

    return (
        <Routes>
            <Route exact path={`/emailVerification`} element={<AccountConfirmation navigate={navigate} location={location} params={getParams("/emailVerification")} />}/>
            <Route exact path={`/`} element={<Login navigate={navigate} location={location} params={getParams("/")} />}/>
            <Route exact path={`/login`} element={<Login navigate={navigate} location={location} params={getParams("/login")} />}/>
            <Route exact path={`/middlepage`} element={<JumpAuthorize navigate={navigate} location={location} params={getParams("/middlepage")} />}/>
            <Route exact path={`/select-organization`} element={<PrivateRoute />}>
                <Route path={`/select-organization`} element={<SelectOrganization navigate={navigate} location={location} params={getParams("/select-organization")} />}/>
            </Route>
            <Route exact path={`/admin`} element={<PrivateRoute />}>
                <Route path={`/admin/dashboard`} element={<Dashboard navigate={navigate} location={location} params={getParams("/admin/dashboard")} />}/>
                <Route exact path={`/admin/groups`} element={<GroupsList navigate={navigate} location={location} params={getParams("/admin/groups")} />}/>
                <Route path={`/admin/groups/:action`} element={<GroupDefinition navigate={navigate} location={location} params={getParams("/admin/groups/:action")} />}/>
                <Route exact path={`/admin/departments`} element={<OutletsList navigate={navigate} location={location} params={getParams("/admin/departments")} />}/>
                <Route path={`/admin/departments/:action`} element={<OutletDefinition navigate={navigate} location={location} params={getParams("/admin/departments/:action")} />}/>
                <Route exact path={`/admin/employees`} element={<EmployeesList navigate={navigate} location={location} params={getParams("/admin/employees")} />}/>
                <Route path={`/admin/employees/:action`} element={<EmployeeDefinition navigate={navigate} location={location} params={getParams("/admin/employees/:action")} />}/>
                <Route exact path={`/admin/announcements`} element={<AdminAnnouncement navigate={navigate} location={location} params={getParams("/admin/announcements")} />}/>
                <Route exact path={`/admin/announcements/view`} element={<ViewAnnouncement navigate={navigate} location={location} params={getParams("/admin/announcements/view")} />}/>
                <Route path={`/admin/announcements/add`} element={<AnnouncementDefinition navigate={navigate} location={location} params={getParams("/admin/announcements/add")} />}/>
                <Route exact path={`/admin/attendances/:approvalStatus`} element={<AttendanceManagement navigate={navigate} location={location} params={getParams("/admin/attendances/:approvalStatus")} />}/>
                <Route exact path={`/admin/attendance/settings`} element={<AttendanceSettings navigate={navigate} location={location} params={getParams("/admin/attendance/settings")} />}/>
                <Route exact path={`/admin/attendance/pending-acknowledgements`}
                    element={<AttendancePendingAckApprovals navigate={navigate} location={location} params={getParams("/admin/attendance/pending-acknowledgements")} />}/>
                <Route exact path={`/admin/attendance/pending`} element={<PendingAttendances navigate={navigate} location={location} params={getParams("/admin/attendance/pending")} />}/>
                <Route path={`/admin/attendanceedit/:action`} element={<EditeAttendance navigate={navigate} location={location} params={getParams("/admin/attendanceedit/:action")} />}/>
                <Route exact path={`/admin/attendance/flagged`} element={<RejectedAttendances navigate={navigate} location={location} params={getParams("/admin/attendance/flagged")} />}/>
                <Route exact path={`/admin/contents`} element={<ContentManagement navigate={navigate} location={location} params={getParams("/admin/contents")} />}/>
                <Route exact path={`/admin/contents/add`} element={<ContentDefinition navigate={navigate} location={location} params={getParams("/admin/contents/add")} />}/>
                <Route exact path={`/admin/contents/view`} element={<ViewContent navigate={navigate} location={location} params={getParams("/admin/contents/view")} />}/>
                <Route exact path={`/admin/settings`} element={<Settings navigate={navigate} location={location} params={getParams("/admin/settings")} />}/>
                <Route exact path={`/admin/settings/organization/detail`} element={<SettingsCompany navigate={navigate} location={location} params={getParams("/admin/settings/organization/detai")} />}/>
                <Route exact path={`/admin/leaveTypes/create`} element={<CreateLeaves navigate={navigate} location={location} params={getParams("/admin/leaveTypes/create")} />}/>
                <Route path={`/admin/activity-logs`} element={<ActivityLog navigate={navigate} location={location} params={getParams("/admin/activity-logs")} />}/>
                <Route path={`/admin/account-settings`} element={<AccountSettings navigate={navigate} location={location} params={getParams("/admin/account-settings")} />}/>
            </Route>
            <Route exact path={`/user`} element={<PrivateRoute />}>
                <Route path={`/user/dashboard`} element={<UserDashboard navigate={navigate} location={location} params={getParams("")} />}/>
                <Route exact path={`/user/attendance/:for`} element={<UserAttendance navigate={navigate} location={location} params={getParams("/user/attendance/:for")} />}/>
                <Route exact path={`/user/attendance/view/:attendanceId`} element={<UserViewAttendanceDetails navigate={navigate} location={location} params={getParams("/user/attendance/view/:attendanceId")} />}/>
                <Route exact path={`/user/announcements`} element={<UserAnnouncements navigate={navigate} location={location} params={getParams("/user/announcements")} />}/>
                <Route exact path={`/user/announcements/view`} element={<UserViewAnnouncement navigate={navigate} location={location} params={getParams("/user/announcements/view")} />}/>
                <Route exact path={`/user/activity-logs`} element={<UserActivityLog navigate={navigate} location={location} params={getParams("/user/activity-logs")} />}/>
            </Route>
        </Routes>
    );

};
