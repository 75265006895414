import React from 'react';
import {connect} from "react-redux";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import {Formik} from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import {actionCreateNewContent} from "../../../../actions/admin/content.actions";
import {TextEditor} from "../../../shared/text-editor";

class ContentDefinition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formInitialValues: {...initialEmptyFormValues},
            editorConfig: {
                readonly: false,
                spellcheck: true,
                uploader: {
                    insertImageAsBase64URI: true
                }
            }
        };
    }

    handleSubmit = async (values) => {
        await this.props.actionCreateNewContent(values);
        this.props.navigate('/admin/contents')
    };

    render() {
        const {formInitialValues} = this.state;
        const {lang} = this.props;
        return (
            <Container fluid className="mt-4 mb-5">
                <Card>
                    <Card.Body>
                        <Card.Title>{lang.AddContent}</Card.Title>
                        <Formik
                            initialValues={formInitialValues}
                            validationSchema={validationSchema}
                            // enableReinitialize={true}
                            onSubmit={this.handleSubmit}>
                            {({
                                  handleSubmit,
                                  handleChange,
                                  setFieldValue,
                                  values,
                                  errors
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="title">
                                        <Form.Label>{lang.Title}</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            name={"title"}
                                            value={values.title}
                                            onChange={handleChange}
                                            isInvalid={!!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} md={6}>
                                            <Form.Label>{lang.TypeOfContent}</Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                onChange={event => setFieldValue('type', event.target.value)}>
                                                <option value="guidelines">Guidelines</option>
                                                <option value="newbieHandbook">Newbie Handbook</option>
                                                <option value="tipsAndTricks">Tips & Tricks</option>
                                                <option value="reminder">Reminder</option>
                                                <option value="tasks">Tasks</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6}>
                                            <Form.Label>{lang.Status}</Form.Label>
                                            <Form.Control
                                                as={'select'}
                                                onChange={event => setFieldValue('status', event.target.value)}>
                                                <option value="A">Active</option>
                                                <option value="I">Inactive</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group>
                                        <Form.Label>{lang.Description}</Form.Label>
                                        <TextEditor
                                            value={values.description}
                                            onBlur={newContent => setFieldValue('description', newContent)}/>
                                        <small className="text-danger">{errors.description}</small>
                                    </Form.Group>
                                    <Button className="mt-3" type="submit">{lang.Add}</Button>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Container>)
    }
}

const mapStateToProps = ({langReducer}) => ({
    lang: langReducer.lang
});

export default connect(mapStateToProps, {actionCreateNewContent})(ContentDefinition);

const initialEmptyFormValues = {
    title: '',
    description: '',
    status: 'A',
    type: 'guidelines'
};

const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    status: Yup.string().required('Status is required'),
});
