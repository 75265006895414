import React,{useState} from 'react';
import {connect} from "react-redux";
import {getGroupsAll, deleteGroup, restoreGroup} from '../../../../actions/admin/group.actions';
import DataTable from 'react-data-table-component';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";

class GroupsList extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            dataSet: {targetId : null, operation: null},
        };
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleConfirmModal = this.handleConfirmModal.bind(this);
    }
    componentDidMount() {
        this.props.getGroupsAll();
    }

    editGroup = (groupId) => () => {
        this.props.navigate(`/admin/groups/edit?groupId=${groupId}`)
    };

    handleCloseModal = () => {
        this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });

    };

    handleOpenModal = (targetId, typeOp) => {
        this.setState({ showModal: true, dataSet: {targetId : targetId, operation: typeOp.toUpperCase()} });
    };

    handleConfirmModal = () => {
        if (this.state.dataSet.operation === "DELETE") {
            this.props.deleteGroup(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.getGroupsAll();
                this.props.navigate('/admin/groups');
            });
        }
        if (this.state.dataSet.operation === "RESTORE") {
            this.props.restoreGroup(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.getGroupsAll();
                this.props.navigate('/admin/groups');
            });
        }

    }

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Name,
                selector: 'name',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.name} </div>
                )
            },
            {
                name: lang.Description,
                selector: 'description',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.description} </div>
                )
            },
            {
                name: lang.Status,
                selector: 'status',
                sortable: true,
                cell: row => (
                    <h6>  
                        {row.status === "A" && <Badge variant="success">{lang.Active}</Badge>}
                        {row.status === "I" && <Badge variant="danger">{lang.Inactive}</Badge>}
                        {row.status === "D" && <Badge variant="warning">{lang.Deleted}</Badge>}
                    </h6>
                )
            },
            {
                name: '',
                button: true,
                cell: row => (
                    <>
                        <Button variant="info" size="sm" className="mr-1" onClick={this.editGroup(row._id)}><i
                            className="fas fa-wrench"/></Button>
                            {row.isDeleted ? 
                            <Button variant="danger" size="sm" onClick={this.handleOpenModal.bind(this, row._id, "restore")}><i className="fas fa-undo"/></Button> :
                            <Button variant="danger" size="sm" onClick={this.handleOpenModal.bind(this, row._id, "delete")}><i className="far fa-trash-alt"/></Button>
                            }
                        
                    </>)
            }
        ];
    };

    render() {
        const {groups, lang} = this.props;
        return (
            <>
                <Container fluid className="pt-4">
                    <Card>
                        <Card.Body>
                            <DataTable
                                title={lang.AllGroups}
                                columns={this.renderColumns()}
                                data={groups}
                                responsive={true}
                                pagination={true} />
                        </Card.Body>
                    </Card>
                </Container>
                
                <Modal show={this.state.showModal} onHide={this.handleCloseModal.bind(this)}>
                    <Modal.Header closeButton>
                    <Modal.Title>{lang.Confirmation}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><b>"{this.state.dataSet.operation}"</b> group, {lang.ConfirmationQuestion} </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal.bind(this)}>
                        {lang.No}
                    </Button>
                    <Button variant="primary" onClick={this.handleConfirmModal.bind(this)}>
                        {lang.Yes}
                    </Button>
                    </Modal.Footer>
                </Modal>
                
                
            </>
            
        );
    }
}

const mapStateToProps = ({admin: {groupReducer}, langReducer}) => ({
    groups: groupReducer.groups,
    lang: langReducer.lang
});


export default connect(mapStateToProps, ({getGroupsAll, deleteGroup, restoreGroup}))(GroupsList);
