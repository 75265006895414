import {authenticationConstants, userConstants} from "../constants";
import axios from './axiosConfig';
import {trackPromise} from "react-promise-tracker";
import {toast} from "react-toastify";

export const loginAction = (data) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`auth/signin`, data));

        const {
            data: {
                _id, 
                firstName, 
                lastName, 
                email, 
                contact, 
                userName, 
                org, 
                activeOrg, 
                isSuperAdmin,
                token,
                organizations,
            }
        } = response.data;

        dispatch({
            type: authenticationConstants.LOGGED_IN,
            payload: {
                _id,
                firstName,
                lastName,
                email,
                contact,
                userName,
                isSuperAdmin,
                token,
                organizations,
                isOwner: org?.some(o => o.isOwner),
                activeOrg,
                attachedOrgs: org
            }
        });
        return response;
    } catch (err) {
        const {response} = err;
        if (response && response.status === 400) {
            toast.error(response.data.message);
        }
        throw err;
    }
};

export const verifyToken = (data, navigate) => async dispatch => {
    try {
        const response = await trackPromise(axios.post(`auth/verifyToken`, data));
        const {
            data: {
                _id, 
                firstName, 
                lastName, 
                email, 
                contact, 
                userName, 
                org, 
                activeOrg, 
                isSuperAdmin,
                token,
                organizations,
                selectedOrg
            }
        } = response.data;

        dispatch({
            type: authenticationConstants.LOGGED_IN,
            payload: {
                _id,
                firstName,
                lastName,
                email,
                contact,
                userName,
                isSuperAdmin,
                token,
                organizations,
                isOwner: org?.some(o => o.isOwner),
                activeOrg,
                attachedOrgs: org
            }
        });

        dispatch({type: userConstants.SET_VERIFY_ORGANIZATION, payload: selectedOrg})

        const organization = organizations.find(org => org.corpUid === data.organizationId);
        if (organization?.isSuperAdmin || organization?.isAdmin) {
            navigate("/admin/dashboard");
        } else {
            navigate("/user/dashboard");
        }

        return response;
    } catch (err) {
        const {response} = err;
        if (response && response.status === 400) {
            toast.error(response.data.message);
        }
        throw err;
    }
};


export const logoutAction = () => dispatch => {
    dispatch({type: authenticationConstants.LOG_OUT});
};
