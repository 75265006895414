import React from "react";
import {connect} from "react-redux";
import {
    addEmployee,
    getEmployee,
    getEmployees,
    updateEmployee,
} from "../../../../actions/admin/employee.actions";
import {getGroups} from "../../../../actions/admin/group.actions";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {Formik, FieldArray, Field} from "formik";
import * as Yup from "yup";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import "./Employee.css";
import {getOutlets} from "../../../../actions/admin/outlet.actions";
import ChangePassword from "./ChangePassword";

const countryJson = require("countrycitystatejson");

class EmployeeDefinition extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            action: "",
            countries: [],
            employeeId: "",
            isOwner: false,
            isSuperAdmin: false,
            isAdmin: false,
            isEmployee: true,
            error: '',
            initialFormValues: {
                ...emptyInitialValues,
                country: props.selectedOrg?.country,
            },
        };
    }

    componentDidMount() {
        const {getGroups, getEmployees, getOutlets} = this.props;
        getGroups();
        getEmployees();
        getOutlets();
        var user = JSON.parse(localStorage.getItem('user'));
        if (user.isOwner) {
            this.setState({isOwner: true})
        }


        const countries = countryJson.getCountries().sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });

        const {
            params,
        } = this.props;
        this.setState({action: params.action, countries});

        if (params.action === "edit") {
            const {search} = this.props.location;
            const queryParams = new URLSearchParams(search);
            if (queryParams.get("employeeId")) {
                const employeeId = queryParams.get("employeeId");
                this.setState({employeeId});
                this.props.getEmployee(employeeId).then(() => {
                    this.setFormStateForEdit();
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.params.action !== this.props.params.action) {
            this.setState({initialFormValues: emptyInitialValues});
        }
        if (prevState.isSuperAdmin !== this.state.isSuperAdmin || prevState.isAdmin !== this.state.isAdmin || prevState.isEmployee !== this.state.isEmployee) {

            if (!this.state.isSuperAdmin && !this.state.isAdmin && !this.state.isEmployee) {
                this.setState({error: 'At least one account type is required'})
            } else {
                this.setState({error: ''});
            }
        }

    }

    createNewEmployee = async (values) => {
        try {
            const accountType = {
                isSuperAdmin: this.state.isSuperAdmin,
                isAdmin: this.state.isAdmin,
                isEmployee: this.state.isEmployee,
            }
            values.accountType = accountType;
                await this.props.addEmployee(values);
                this.props.navigate("/admin/employees");
        } catch (e) {
            throw e;
        }
    };

    handleSubmit = async (values, actions) => {
        try {
            if (this.state.isSuperAdmin || this.state.isAdmin || this.state.isEmployee) {
                this.state.action === "add"
                    ? await this.createNewEmployee(values)
                    : await this.updateEmployee(values);
                actions.resetForm(emptyInitialValues);
            }
        } catch (err) {
            console.error(err);
        }
    };

    setFormStateForEdit = () => {
        const {employee, selectedOrg} = this.props;
        let {initialFormValues} = this.state;
        const empOrgDetails = employee.org?.find(
            (o) => o.orgId === selectedOrg._id
        );
        if (empOrgDetails) {
            initialFormValues.employee.otSupervisor =
                empOrgDetails.employee.otSupervisor;
            initialFormValues.employee.leaveManager =
                empOrgDetails.employee.leaveManager;
            this.setState({
                isSuperAdmin: empOrgDetails.isSuperAdmin,
                isAdmin: empOrgDetails.isAdmin,
                isEmployee: empOrgDetails.isEmployee
            });
        }

        initialFormValues = {...initialFormValues, ...employee};
        this.setState({initialFormValues});
    };

    updateEmployee = async (values) => {
        try {
            const accountType = {
                isSuperAdmin: this.state.isSuperAdmin,
                isAdmin: this.state.isAdmin,
                isEmployee: this.state.isEmployee,
            }
            values.accountType = accountType;
                await this.props.updateEmployee(values);
                this.props.navigate("/admin/employees");
        } catch (err) {
            throw err;
        }
    };

    getStates = (countryCode) => {
        if (countryCode) {
            return countryJson.getStatesByShort(countryCode);
        }
        return [];
    };

    formatSelectedGroupsForFormik = (selectedGroups) => {
        return selectedGroups.map((group) => group.value);
    };

    render() {

        const {action, countries, initialFormValues, employeeId, isOwner} = this.state;
        let {groups, employees, employee, lang, outlets} = this.props;
        if (action === "edit") {
            employees = employees.filter((emp) => emp._id !== employee._id);
        }
        return (
            <Container fluid>
                <Card className="mt-4 mb-5 shadow">
                    <Card.Body>
                        <Card.Title>
                            {action === "add" ? lang.AddEmployee : lang.EditEmployee}
                        </Card.Title>

                        {action === "edit" && <ChangePassword employeeId={employeeId}/>}

                        <Formik
                            validationSchema={action === "add" ? validation_schema : editValidation_schema}
                            initialValues={initialFormValues}
                            enableReinitialize={true}
                            onSubmit={this.handleSubmit}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  setFieldValue,
                                  values,
                                  errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId="firstName">
                                            <Form.Label>{lang.FirstName}</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={values.firstName}
                                                name="firstName"
                                                onChange={handleChange}
                                                isInvalid={!!errors.firstName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.firstName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="lastName">
                                            <Form.Label>{lang.LastName}</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={values.lastName}
                                                name="lastName"
                                                onChange={handleChange}
                                                isInvalid={!!errors.lastName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.lastName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md={4} controlId="userName">
                                            <Form.Label>{lang.Username}*</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                value={values.userName}
                                                name="userName"
                                                onChange={handleChange}
                                                isInvalid={!!errors.userName}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.userName}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {action === "add" && (
                                            <Form.Group as={Col} md={4} controlId="password">
                                                <Form.Label>{lang.Password}</Form.Label>
                                                <Form.Control
                                                    value={values.password}
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.password}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        )}

                                        <Form.Group as={Col} md={4} controlId="email">
                                            <Form.Label>{lang.Email}</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onChange={handleChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} md={4} controlId="contact">
                                            <Form.Label>{lang.Contact}</Form.Label>
                                            <Form.Control
                                                value={values.contact}
                                                name="contact"
                                                onChange={handleChange}
                                                isInvalid={!!errors.contact}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.contact}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId="dob">
                                            <Form.Label>{lang.DateOfBirth}</Form.Label>
                                            <DatePicker
                                                selected={
                                                    values.dob ? new Date(values.dob) : values.dob
                                                }
                                                onChange={(date) => setFieldValue("dob", date)}
                                                dateFormat="dd-MMM-yyyy"
                                                className="form-control"
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="gender">
                                            <Form.Label>{lang.Gender}</Form.Label>
                                            <Form.Control
                                                as="select"
                                                placeholder="Select gender"
                                                value={values.gender}
                                                name="gender"
                                                onChange={handleChange}
                                            >
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>
                                    <FieldArray
                                        name="address"
                                        render={({push, remove}) => (
                                            <Form.Row>
                                                {values.address.map((address, index) => (
                                                    <React.Fragment key={`address-${index}`}>
                                                        <Form.Group
                                                            as={Col}
                                                            md={index !== 0 ? 11 : 12}
                                                            controlId={`addressLine${index}`}
                                                        >
                                                            <Form.Label>
                                                                {lang.AddressLine} {index + 1}
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={address}
                                                                name={`address[${index}]`}
                                                                onChange={handleChange}
                                                            />
                                                        </Form.Group>
                                                        {index !== 0 && (
                                                            <Form.Group
                                                                as={Col}
                                                                md={1}
                                                                className="text-center pt-4"
                                                            >
                                                                <i
                                                                    className="far fa-trash-alt text-danger mt-3 cursor-pointer"
                                                                    onClick={() => remove(index)}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                                <Form.Group>
                                                    <Button
                                                        type="button"
                                                        variant="info"
                                                        size="sm"
                                                        onClick={() => push("")}
                                                    >
                                                        Add more addresses
                                                    </Button>
                                                </Form.Group>
                                            </Form.Row>
                                        )}
                                    />

                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId="country">
                                            <Form.Label>{lang.Country}</Form.Label>
                                            <Select
                                                options={countries.map((country) => ({
                                                    value: country.shortName,
                                                    label: country.emoji + "  " + country.name,
                                                }))}
                                                value={countries
                                                    .filter(
                                                        (country) => country.shortName === values.country
                                                    )
                                                    .map((country) => ({
                                                        value: country.shortName,
                                                        label: country.emoji + "  " + country.name,
                                                    }))}
                                                onChange={(selected) =>
                                                    setFieldValue("country", selected.value)
                                                }
                                                isSearchable
                                            />
                                            <Form.Control
                                                name="country"
                                                defaultValue={values.country}
                                                className="d-none"
                                                isInvalid={!!errors.country}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.country}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="state">
                                            <Form.Label>{lang.State}</Form.Label>
                                            <Form.Control
                                                as="select"
                                                value={values.state}
                                                name="state"
                                                onChange={handleChange}
                                            >
                                                <option>Select</option>
                                                {this.getStates(values.country)?.map((state) => (
                                                    <option value={state} key={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md={6} controlId="groups">
                                            <Form.Label>{lang.Groups}</Form.Label>
                                            <Select
                                                options={groups.map((group) => ({
                                                    value: group._id,
                                                    label: group.name,
                                                }))}
                                                value={groups
                                                    .filter((group) =>
                                                        values.group.some(
                                                            (groupId) => groupId === group._id
                                                        )
                                                    )
                                                    .map((group) => ({
                                                        value: group._id,
                                                        label: group.name,
                                                    }))}
                                                onChange={(selected) => {
                                                    if (!selected) {
                                                        selected = [];
                                                    }
                                                    const groupIds = this.formatSelectedGroupsForFormik(
                                                        selected
                                                    );
                                                    setFieldValue("group", groupIds);
                                                }}
                                                isSearchable
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="outlets">
                                            <Form.Label>{lang.Outlets}</Form.Label>
                                            <Select
                                                options={outlets.map((outlet) => ({
                                                    value: outlet,
                                                    label: outlet.name,
                                                }))}
                                                value={outlets
                                                    .filter((outlet) =>
                                                        values.outlet?.some(
                                                            (outletId) => outletId._id === outlet._id
                                                        )
                                                    )
                                                    .map((outlet) => ({
                                                        value: outlet,
                                                        label: outlet.name,
                                                    }))}
                                                onChange={(selected) => {
                                                    if (!selected) {
                                                        selected = [];
                                                    }
                                                    const outletIds = selected.map(
                                                        (outlet) => outlet.value
                                                    );
                                                    setFieldValue("outlet", outletIds);
                                                }}
                                                isSearchable
                                                isMulti
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md={6}>
                                            <Form.Label>{lang.Supervisors}</Form.Label>
                                            <Select
                                                options={employees.map((emp) => ({
                                                    value: emp._id,
                                                    label: `${emp.firstName} ${emp.lastName}`,
                                                }))}
                                                value={employees
                                                    .filter((emp) =>
                                                        values.employee.otSupervisor.some(
                                                            (id) => id === emp._id
                                                        )
                                                    )
                                                    .map((emp) => ({
                                                        value: emp._id,
                                                        label: `${emp.firstName} ${emp.lastName}`,
                                                    }))}
                                                onChange={(selected) => {
                                                    if (!selected) {
                                                        selected = [];
                                                    }
                                                    const empIds = selected.map((s) => s.value);
                                                    setFieldValue("employee.otSupervisor", empIds);
                                                }}
                                                onRem
                                                isSearchable
                                                isMulti
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6}>
                                            <Form.Label>{lang.Managers}</Form.Label>
                                            <Select
                                                options={employees.map((emp) => ({
                                                    value: emp._id,
                                                    label: `${emp.firstName} ${emp.lastName}`,
                                                }))}
                                                value={employees
                                                    .filter((emp) =>
                                                        values.employee.leaveManager.some(
                                                            (id) => id === emp._id
                                                        )
                                                    )
                                                    .map((emp) => ({
                                                        value: emp._id,
                                                        label: `${emp.firstName} ${emp.lastName}`,
                                                    }))}
                                                onChange={(selected) => {
                                                    if (!selected) {
                                                        selected = [];
                                                    }
                                                    const empIds = selected.map((s) => s.value);
                                                    setFieldValue("employee.leaveManager", empIds);
                                                }}
                                                isSearchable
                                                isMulti
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row className="my-2">
                                        <Form.Group as={Col} md={4}>
                                            <h6>{lang.AccountType}</h6>

                                            <div className="d-flex">
                                                <label className="mr-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.isSuperAdmin}
                                                        onChange={() =>
                                                            this.setState({
                                                                isSuperAdmin: !this.state.isSuperAdmin,
                                                                isAdmin: false
                                                            })
                                                            // setFieldValue(
                                                            //     "accountType.isSuperAdmin",
                                                            //     (values.accountType.isSuperAdmin = !values.accountType.isSuperAdmin)
                                                            // )
                                                        }
                                                        disabled={!isOwner}
                                                    />{" "}
                                                    {lang.SuperAdmin}
                                                </label>
                                                <label className="mr-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.isAdmin}
                                                        onChange={() =>
                                                            this.setState({
                                                                isAdmin: !this.state.isAdmin,
                                                                isSuperAdmin: false
                                                            })
                                                            // setFieldValue(
                                                            //     "accountType.isAdmin",
                                                            //     (values.accountType.isAdmin = !values.accountType.isAdmin)
                                                            // )
                                                        }
                                                    />{" "}
                                                    {lang.Admin}
                                                </label>
                                                <label className="mr-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.isEmployee}
                                                        onChange={() =>
                                                            this.setState({isEmployee: !this.state.isEmployee})
                                                            // setFieldValue(
                                                            //     "accountType.isEmployee",
                                                            //     (values.accountType.isEmployee = !values.accountType.isEmployee)
                                                            // )
                                                        }
                                                    />{" "}
                                                    {lang.User}
                                                </label>


                                            </div>

                                            <small className="text-danger">
                                                {this.state.error}
                                            </small>
                                        </Form.Group>
                                        <Form.Group as={Col} md={4}>
                                            <h6>{lang.AccountActivationMethods}</h6>
                                            <div className="d-flex">
                                                <label className="mr-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={values.activationType.byEmail}
                                                        onChange={() =>
                                                            setFieldValue(
                                                                "activationType.byEmail",
                                                                !values.activationType.byEmail
                                                            )
                                                        }
                                                    />{" "}
                                                    By
                                                    {lang.Email}
                                                </label>

                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={values.activationType.byContact}
                                                        onChange={() =>
                                                            setFieldValue(
                                                                "activationType.byContact",
                                                                !values.activationType.byContact
                                                            )
                                                        }
                                                    />{" "}
                                                    By
                                                    {lang.Contact}
                                                </label>
                                            </div>
                                            <small className="text-danger">
                                                {errors.activationType}
                                            </small>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="status">
                                            <Form.Label>{lang.Status}</Form.Label>
                                            <Form.Control
                                                as="select"
                                                placeholder="Select status"
                                                value={values.status}
                                                name="status"
                                                onChange={handleChange}
                                            >
                                                <option value="active">Active</option>
                                                <option value="pre-active">Pre Active</option>
                                                <option value="inactive">In Active</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Form.Row>

                                    <Button type="submit" variant="primary">
                                        {action === "add" ? lang.Add : lang.Save}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
            </Container>
        );
    }
}

const mapStateToProps = ({
                             admin: {groupReducer, employeeReducer, outletReducer},
                             organizationReducer,
                             langReducer,
                         }) => ({
    employees: employeeReducer.employees,
    employee: employeeReducer.employee,
    groups: groupReducer.groups,
    outlets: outletReducer.outlets,
    lang: langReducer.lang,
    selectedOrg: organizationReducer.selectedOrganization,
});

export default connect(mapStateToProps, {
    addEmployee,
    getEmployee,
    updateEmployee,
    getGroups,
    getEmployees,
    getOutlets,
})(EmployeeDefinition);

const emptyInitialValues = {
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    email: "",
    contact: "",
    gender: "male",
    dob: "",
    address: ["", ""],
    country: "",
    state: "",
    postalCode: "",
    group: [],
    outlet: [],
    status: "A",
    employee: {
        otSupervisor: [],
        leaveManager: [],
    },
    activationType: {
        byEmail: true,
        byContact: false,
    },

};

const validation_schema = Yup.object({
    userName: Yup.string().required("Field is required"),
    password: Yup.string().required("Field is required"),
    accountType: Yup.object({
        isAdmin: Yup.boolean(),
        isEmployee: Yup.boolean(),
        isSuperAdmin: Yup.boolean(),
    })
});

const editValidation_schema = Yup.object({
    userName: Yup.string().required("Field is required"),
    accountType: Yup.object({
        isAdmin: Yup.boolean(),
        isEmployee: Yup.boolean(),
        isSuperAdmin: Yup.boolean(),
    })
});
