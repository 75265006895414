import React from 'react';
import {connect} from "react-redux";
import Container from "react-bootstrap/Container";
import DataTable from "react-data-table-component";
import {actionGetActivityLog} from "../../../../actions/admin/activity-log.actions";
import { FilteredTable } from "../../shared/filteredTable";
import { convertDateTimeToCustomFormat } from '../../../../utlis';

class ActivityLog extends React.Component {
    componentDidMount() {
        this.props.actionGetActivityLog();
    }

    renderColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: "Date",
                selector: 'date',
                sortable: true,
                cell: row => convertDateTimeToCustomFormat(row.date)
            },
            {
                name: "Status",
                selector: 'status',
                width: "150px",
                sortable: true,
                cell: (row) => {
                    if (!row.status)
                        return "";
                    else
                        return (
                            <span 
                                style={{
                                    backgroundColor: String(row.status).toLowerCase() === "success" ? "#28a745" : "#dc3545",
                                    borderRadius: "20px",
                                    color: "white",
                                    textAlign: "center",
                                    padding: "2px 8px"
                                }}
                            >
                                {row.status}
                            </span>
                        )
                }
            },
            {
                name: "Type",
                selector: 'type',
                sortable: true,
                cell: row => row.type
            },
            {
                name: "Operator",
                selector: 'operator',
                sortable: true,
                cell: row => row.operator.userName
            },
            {
                name: "Subject",
                selector: 'subject',
                sortable: true,
                cell: row => row.subject
            },
            {
                name: lang.Log,
                selector: 'description',
                sortable: true,
                width: "600px",
                cell: row => (
                    <div style={{whiteSpace: "break-spaces"}}>
                        {row.description}
                    </div>)
            }
        ];
    };

    render() {
        const {activityLogs, lang} = this.props;
        return (
            <Container fluid>
                <FilteredTable
                    title={lang.ActivityLog}
                    field={"description"}
                    columns={this.renderColumns()}
                    list={activityLogs}
                    pagination={true}
                    striped
                    keyField={'_id'}
                />
            </Container>
        );
    }
}

const mapStateToProps = ({user: {activityLogReducer}, langReducer}) => ({
    activityLogs: activityLogReducer.activityLogs,
    lang: langReducer.lang
});

export default connect(mapStateToProps, ({actionGetActivityLog}))(ActivityLog);
