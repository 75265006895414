import React, {useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import Compress from "compress.js";
import Camera from "../../../dashboard/Camera";
const enableCamera = process.env.REACT_APP_CAMERA_ENABLE;

export const WriteAcknowledgementModal = (props) => {
    const {showModal, handleCloseModal, submitAcknowledgement, lang} = props;
    const [file, setFile] = useState('');
    const [fileError, setFileError] = useState(false);
    const [acknowledgement, setAcknowledgement] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);


    // const handleFileChange = (event) => {
    //     setFile(event.target.files[0])
    // };

    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //     event.stopPropagation();
        //     return;
        // }

        if (!file) {
            setFileError(true);
            return false;
        }

        const formData = new FormData();
        if (selectedImage) {
            formData.append('image', selectedImage);
        }

        formData.append('acknowledgement', acknowledgement);
        
        submitAcknowledgement(formData);
        window.location.reload();
    };

    const handleFileChange = async event => {
        // const resized = await this.resizeImageFn(event.target.files[0]);
        const resized = await resizeImageFn(event);
        
        setSelectedImage(resized);
    };

    const resizeImageFn = async (file) => {
        const newCompress = new Compress();
        const resizedImage = await newCompress.compress([file], {
            size: 1, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 300, // the max width of the output image, defaults to 1920px
            maxHeight: 300, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        });

        const img = resizedImage[0];
        const base64str = img.data;
        const imgExt = img.ext;
        return Compress.convertBase64ToFile(base64str, imgExt);
    };

    const handleImageChange = (event) => {
        // console.log("imge change", event);
        setFile(event);
        setFileError(false);
        handleFileChange(event);
    };


    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{lang.WriteAcknowledgement}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group">
                        { enableCamera && <Camera 
                            handleFileChange ={handleImageChange}
                        /> }
                        {/* <label>{lang.UploadImage}</label>
                        <input className="form-control" type="file" onChange={handleFileChange} accept="image/*"
                               required/>
                        {fileError && <small className="text-danger">Picture is required</small>} */}
                    </div>
                    <div className="form-group">
                        <label>{lang.Acknowledgement}</label>
                        <textarea className="form-control" value={acknowledgement}
                                  onChange={event => setAcknowledgement(event.target.value)}/>
                    </div>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" size="sm" onClick={handleCloseModal}>Close</Button>
                <Button variant="primary" size="sm" onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
};
