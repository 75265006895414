import React from 'react';
import {connect} from "react-redux";
import {actionGetAnnouncements, actionDeleteAnnouncement, actionRestoreAnnouncement} from "../../../../actions/admin/announcement.actions";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/esm/Button";
import {convertDateToCustomFormat} from "../../../../utlis";

class AdminAnnouncement extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            dataSet: {targetId : null, operation: null},
        };
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleConfirmModal = this.handleConfirmModal.bind(this);
    }
    componentDidMount() {
        this.props.actionGetAnnouncements();
    }

    handleCloseModal = () => {
        this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });

    };

    handleOpenModal = (targetId, typeOp) => {
        this.setState({ showModal: true, dataSet: {targetId : targetId, operation: typeOp.toUpperCase()} });
    };

    handleConfirmModal = () => {
        if (this.state.dataSet.operation === "DELETE") {
            this.props.actionDeleteAnnouncement(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.actionGetAnnouncements();
                this.props.navigate('/admin/announcements');
            });
        }
        if (this.state.dataSet.operation === "RESTORE") {
            this.props.actionRestoreAnnouncement(this.state.dataSet.targetId).then(() => {
                this.setState({ showModal: false, dataSet: {targetId : null, operation: null} });
                this.props.actionGetAnnouncements();
                this.props.navigate('/admin/announcements');
            });
        }

    }
    
    renderTableColumns = () => {
        const {lang} = this.props;
        return [
            {
                name: lang.Title,
                selector: 'title',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} > {row.title} </div>
                )
            },
            {
                name: lang.FromDate,
                selector: 'fromDate',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} >{convertDateToCustomFormat(row.fromDate)}</div>
                )
            },
            {
                name: lang.ToDate,
                selector: 'tillDate',
                sortable: true,
                cell: row => (
                    <div style={{ textDecoration : row.isDeleted ? 'line-through' : 'none', color: row.isDeleted ? 'grey' : 'black' }} >{convertDateToCustomFormat(row.tillDate)}</div>
                )
            },
            {
                name: lang.Actions,
                button: true,
                cell: row => (
                    <>
                        <i className="fas fa-eye cursor-pointer mr-1 text-info"
                           onClick={() => this.onViewAnnouncementDetails(row._id)}/>|
                        
                        {row.isDeleted ? 
                            <i className="fas fa-undo cursor-pointer ml-1 text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Restore User"
                                onClick={this.handleOpenModal.bind(this, row._id, "restore")}
                            />
                            :
                            <i className="far fa-trash-alt cursor-pointer ml-1 text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete User"
                                onClick={this.handleOpenModal.bind(this, row._id, "delete")}
                            />
                           }
                    </>)

            }
        ];
    };

    onViewAnnouncementDetails = (announcementId) => {
        this.props.navigate(`/admin/announcements/view?id=${announcementId}`);
    };


    render() {
        const {announcements, lang} = this.props;

        return (
            <>
            <Container fluid className="pt-4">
                <Card>
                    <Card.Body>
                        <DataTable
                            title={lang.Announcements}
                            columns={this.renderTableColumns()}
                            data={announcements}
                            pagination={true}
                        />
                    </Card.Body>
                </Card>
            </Container>
                <Modal show={this.state.showModal} onHide={this.handleCloseModal.bind(this)}>
                    <Modal.Header closeButton>
                    <Modal.Title>{lang.Confirmation}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><b>"{this.state.dataSet.operation}"</b> announcement, {lang.ConfirmationQuestion} </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseModal.bind(this)}>
                        {lang.No}
                    </Button>
                    <Button variant="primary" onClick={this.handleConfirmModal.bind(this)}>
                        {lang.Yes}
                    </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({admin: {announcementReducer}, langReducer}) => ({
    announcements: announcementReducer.announcements,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {actionGetAnnouncements, actionDeleteAnnouncement, actionRestoreAnnouncement})(AdminAnnouncement);
