import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {
    endAttendanceSession,
    endBreakTimeAction,
    getActiveSessionAttendance,
    startBreakTime,
    startNewAttendanceSession
} from '../../../../actions/user/attendance.actions';
import {actionGetAnnouncements} from "../../../../actions/user/announcement.actions";

import {userConstants} from "../../../../constants";
import {DashboardModal} from "./DashboardModal";
import {PunchAttendance} from "./punch-attendance";
import {PunchBreak} from "./punch-break";

import swal from 'sweetalert';
import {actionGetOutlets} from "../../../../actions/user/outlet.actions";
import Alert from "react-bootstrap/Alert";
import {Card} from "react-bootstrap";

import Compress from "compress.js";

const enableCamera = process.env.REACT_APP_CAMERA_ENABLE;

class UserDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            modalOpenedFor: '',
            selectedImage: null,
            punchedInOutlet: {}
        }
    }

    componentDidMount() {
        const {getActiveSessionAttendance, loggedInUser, actionGetAnnouncements, actionGetOutlets} = this.props;
        const userId = loggedInUser._id;
        actionGetOutlets();
        getActiveSessionAttendance(userId).then(() => {
        });
        actionGetAnnouncements(loggedInUser._id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {activeSession, outlets} = this.props;

        if (prevProps.activeSession !== activeSession) {

            if (outlets && outlets.length > 0 && activeSession) {

                if (activeSession.outlet && activeSession.outlet._id) {
                    const found = outlets.find(outlet => outlet._id === activeSession.outlet._id);
                    if (found) {
                        this.setState({punchedInOutlet: found});
                    }

                } else if (activeSession.outlet) {
                    const found = outlets.find(outlet => outlet._id === activeSession.outlet);
                    if (found) {
                        this.setState({punchedInOutlet: found});
                    }
                }
            }
        }


        if (prevProps.unAcknowledgedAnnouncements !== this.props.unAcknowledgedAnnouncements) {
            const {unAcknowledgedAnnouncements} = this.props;
            swal({
                text: `There are ${unAcknowledgedAnnouncements} unacknowledged announcements.`,
                icon: 'info',
                buttons: {
                    cancel: true,
                    viewAnnouncements: {
                        text: 'Go to Announcements',
                        value: 'viewAnnouncements'
                    }
                }
            }).then(value => {
                if (value === 'viewAnnouncements')
                    this.props.navigate('/user/announcements');
            })
        }
    }

    handleModalShow = (openFor) => {
        this.setState({showModal: true, modalOpenedFor: openFor})
    };

    handleModalClose = () => {
        this.setState({showModal: false, modalOpenedFor: '', selectedImage: null})
    };

    handleFileChange = async event => {
        // const resized = await this.resizeImageFn(event.target.files[0]);
        const resized = await this.resizeImageFn(event);
        
        this.setState({selectedImage: resized})
    };


    resizeImageFn = async (file) => {
        const newCompress = new Compress();
        const resizedImage = await newCompress.compress([file], {
            size: 1, // the max size in MB, defaults to 2MB
            quality: 1, // the quality of the image, max is 1,
            maxWidth: 300, // the max width of the output image, defaults to 1920px
            maxHeight: 300, // the max height of the output image, defaults to 1920px
            resize: true // defaults to true, set false if you do not want to resize the image width and height
        });

        const img = resizedImage[0];
        const base64str = img.data;
        const imgExt = img.ext;
        return Compress.convertBase64ToFile(base64str, imgExt);
    };


    AttendanceSubmit = async ({openedFor, selectedOutlet, comments}) => {
        const {startNewAttendanceSession, endAttendanceSession, loggedInUser} = this.props;
        const formData = new FormData();
        if (this.state.selectedImage !== null) {
                formData.append('image',
                    this.state.selectedImage
                );            
        }
        
        formData.append('user', loggedInUser._id);
        try {
            if (openedFor === userConstants.PUNCH_IN) {
                formData.append('outlet', selectedOutlet);
                formData.append('status', userConstants.PUNCH_IN);
                formData.append('punchInComment', comments);
                await startNewAttendanceSession(formData);
            } else if (openedFor === userConstants.PUNCH_OUT) {
                formData.append('punchOutComment', comments);
                formData.append('status', userConstants.PUNCH_OUT);

                const {activeSession} = this.props;

                await endAttendanceSession(activeSession._id, formData);
            } else if (openedFor === userConstants.BREAK_START) {
                const {activeSession, startBreakTime} = this.props;
                formData.append('status', userConstants.BREAK_START);
                formData.append('startComment', comments);
                await startBreakTime(activeSession._id, formData);
            } else if (openedFor === userConstants.BREAK_END) {
                const {activeSession, breakTimes, endBreakTimeAction} = this.props;
                const found = breakTimes.find(br => br.startTime && !br.endTime);
                formData.append('breakId', found._id);
                formData.append('endComment', comments);
                formData.append('status', userConstants.BREAK_END);
                await endBreakTimeAction(activeSession._id, formData);
            }
            this.handleModalClose();

        } catch (ex) {
            throw ex;
        }
    };


    render() {
        const {showModal, modalOpenedFor, punchedInOutlet} = this.state;
        const {activeSession, breakTimes, unAcknowledgedAnnouncements, lang, outlets, loggedInUser} = this.props;

        return (
            <Container className="mt-5">
                {activeSession &&
                <Row>
                    <Col xs={12}>
                        <Card color="white" className="mb-3">
                            <span
                                className="m-2"><h5>Welcome {loggedInUser.firstName}! You are currently working at {punchedInOutlet.name}</h5></span>
                        </Card>

                    </Col>
                </Row>
                }

                <Row>
                    <Col md={4}>
                        <PunchAttendance activeSession={activeSession} breakTimes={breakTimes}
                                         handleModalShow={this.handleModalShow} lang={lang} />
                    </Col>
                    <Col md={4}>
                        <PunchBreak breakTimes={breakTimes} activeSession={activeSession}
                                    handleModalShow={this.handleModalShow} lang={lang} />
                    </Col>
                    <Col md={4}>
                        <div className="d-flex bg-warning shadow">
                            <span className="px-4 my-auto" style={{fontSize: '45px'}}>
                               <i className="far fa-bell"/>
                            </span>
                            <div className="py-2 flex-grow-1">
                                <span>{lang.ANNOUNCEMENTS}</span> <br/>
                                <span className="font-weight-bold">
                                    {unAcknowledgedAnnouncements} {lang.Unacknowledged}</span>
                                <div className="border border-dark my-2"/>
                                <Button variant="light" size="sm"
                                        onClick={() => this.props.navigate('/user/announcements')}>{lang.ViewAll}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <DashboardModal
                    outlets={outlets}
                    showModal={showModal} handleFileChange={this.handleFileChange}
                    handleModalClose={this.handleModalClose} lang={lang}
                    handleSubmit={this.AttendanceSubmit} openedFor={modalOpenedFor}/>

            </Container>
        );
    }
}

const mapStateToProps = ({
                             user: {attendanceReducer, announcementReducer, outletReducer},
                             authentication,
                             langReducer
                         }) => ({
    activeSession: attendanceReducer.activeSession,
    breakTimes: attendanceReducer.breakTimes,
    loggedInUser: authentication.loggedInUser,
    announcements: announcementReducer.announcements,
    unAcknowledgedAnnouncements: announcementReducer.unAcknowledgedCount,
    outlets: outletReducer.outlets,
    lang: langReducer.lang
});

export default connect(mapStateToProps, {
    getActiveSessionAttendance,
    startNewAttendanceSession,
    endAttendanceSession,
    startBreakTime,
    endBreakTimeAction,
    actionGetAnnouncements,
    actionGetOutlets
})(UserDashboard);
